'use strict';

angular.module('appApp.admin')
  .controller('AdminController', function ($http, $scope, $state, socket, Modal, Auth, User) {
    $scope.vars = {
      tableConfig: {},
      users: []
    };

    User.query().$promise.then(function(users) {
      $scope.vars.users = users
      $scope.vars.tableConfig.options = {
        data: $scope.vars.users,
        idField: 'email',
        // toolbar: '#toolbar',
        search: true,
        maintainSelected: true,
        pagination: true,
        columns: [{
          field: 'name',
          title: 'Nome',
          width: '60%',
          valign: 'middle',
          sortable: true
        }, {
          field: 'email',
          title: 'E-mail',
          valign: 'middle',
          sortable: true
        }, {
          field: 'actions',
          title: 'Azioni',
          align: 'center',
          valign: 'middle',
          events: {
            'click .edit': function (e, value, user) {
              const editable = {
                name: user.name,
                email: user.email,
                password: '',
                role: user.role,
              };

              Modal.admin.editUser(editable, () => {
                $http({
                  method: 'PUT',
                  url: '/api/users/' + user._id + '/update',
                  data: JSON.stringify(editable)
                }).then(res => {
                  if(user.email !== editable.email) {
                    $http({
                      method: 'PUT',
                      url: '/api/v1/key/update/' + user.email + '/' + editable.email
                    })

                    $http({
                      method: 'PUT',
                      url: '/api/alimenti/updateEmail/' + user.email + '/' + editable.email
                    })
                  }

                  User.query().$promise.then($scope.replaceUsers);
                }).catch(console.log);
              })();
            },

            'click .delete': function (e, value, user) {
              Modal.confirm.delete(() => {
                user.$remove(() => {
                  User.query().$promise.then($scope.replaceUsers);
                });
              })('l\'utente ' + user.name);
            }
          },
          formatter: function() {
            return [
              '<a class="edit" href="javascript:void(0)" title="Modifica utente">',
              '<i class="glyphicon glyphicon-pencil"></i>',
              '</a>  ',
              '<a class="delete" href="javascript:void(0)" title="Elimina utente">',
              '<i class="glyphicon glyphicon-remove"></i>',
              '</a>'
            ].join('');
          }
        }]
      };
    });

    $scope.replaceUsers = users => {
      $scope.vars.users.length = 0;
      $scope.vars.users.push.apply($scope.vars.users, users);
    };
  });
